<template lang="html">
  <div class="modals">
    <div class="modals__box w-10/12 md:w-3/12">
      <div class="modals__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .modals {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__box {
    //   width: 30%;
    //   max-width: 300px;
      padding: 30px 50px;
      background-color: #fafafa;
      border-radius: 3px;
      box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.06);
    }
    &__content {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      span {
        margin: 0 0 15px;
        font-size: 1em;
        font-weight: bold;
      }
    }
    &__action {

      ul {
        display: flex;

        li {
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>
