<template>
  <div class="pt-28 pb-12 relative" style="min-height: 80vh">
    <div
      class="flex items-center text-pulpBlue font-semibold text-sm md:text-base cursor-pointer hover:underline absolute top-16 right-4 md:top-20 md:right-28"
      @click="showModals = true"
    >
      <LogoutIconVue width="20" height="20" class="mr-1" iconColor="#3F4E7B" />
      Logout
    </div>
    <div class="flex items-center justify-center">
      <select
        @change="langChange($event)"
        class="py-1 px-3 text-center bg-softGrey text-pulpBlue"
      >
        <option selected disabled>Language Categories</option>
        <option v-for="(data, i) in mediaLanguage" :key="i" :value="data.code">
          {{ data.name }}
        </option>
      </select>
    </div>
    <div v-if="getMedia == null">Loading</div>
    <div class="media" v-if="getMedia != null">
      <ul
        class="md:w-1024 mt-4 mx-auto flex flex-wrap justify-center items-center"
      >
        <li
          class="py-1 px-3 mx-1 mt-1 bg-softGrey text-pulpBlue cursor-pointer"
          v-for="(data, i) in getMedia"
          :key="i"
          :class="whichTab == data.category ? 'active' : ''"
          @click="tabContent(data.category)"
        >
          {{ data.category }}
        </li>
      </ul>
      <div class="media__content">
        <MediaLibraryContent
          :getMedia="getMedia"
          :whichTab="whichTab"
          @changed="onChangedChild"
        />
      </div>
    </div>
    <Modals v-if="showModals">
      <span>Are you sure?</span>
      <div class="modals__action">
        <ul class="flex items-center">
          <li @click="showModals = false">No</li>
          <li
            class="bg-softBlue py-2 px-4 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
            @click="logout"
          >
            Yes
          </li>
        </ul>
      </div>
    </Modals>
  </div>
</template>

<script>
import axios from "axios";

import MediaLibraryContent from "../components/medialibrary/MediaLibraryContent";
import { mapGetters } from "vuex";
import Modals from "../components/modals/Modals.vue";
import LogoutIconVue from "../components/svgicons/LogoutIcon.vue";

export default {
  components: {
    MediaLibraryContent,
    Modals,
    LogoutIconVue,
  },
  data() {
    return {
      showModals: false,
      whichTab: "",
      clearSortedData: null,
      lang: null,
      handleData: false,
      sortedCategories: null,
      sortedLanguage: null,
      showAgile: false,
      mediaLanguage: null,
      selectedLanguage: "English",
      actionLanguage: "ENG",
      whichRole: "",
      whichRole2: "",

      myOptions: {
        autoplaySpeed: 3000,
        speed: 2500,
        fade: "fade",
        autoplay: "autoplay",
        centerMode: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
            },
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false,
            },
          },
        ],
      },
    };
  },
  created() {
    axios({
      method: "GET",
      url: "https://cms.nexgenenglishonline.co/index.php/api/languages",
    })
      .then((res) => {
        // console.log(res);
        this.mediaLanguage = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {
    const data = this.$store.state.stateRoleId;
    const role = data.data.data.acl.ncw.roles[0];

    if (role == "agent") {
      this.whichRole = "NA";
      this.actionPostMedia();
    } else if (role == "reseller") {
      this.whichRole = "NR";
      this.actionPostMedia();
    } else {
      this.whichRole = "NA";
      this.actionPostMedia();
      this.actionPostMediaNR();
    }
  },
  computed: {
    ...mapGetters("moduleGetMedia", {
      getMedia: "getMedia",
      getMediaNR: "getMediaNR",
    }),
    getRoleId() {
      const data = this.$store.state.stateRoleId;

      return data.data.data.acl.ncw.roles[0];
    },
    handleShowMedia() {
      const data = this.getMedia;

      if (data == false) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    actionPostMedia() {
      const bodyFormData = new FormData();
      bodyFormData.set("role_key", this.whichRole);
      bodyFormData.set("lang", this.actionLanguage);
      bodyFormData.set("type", "neo Corporate Website");
      // bodyFormData.set('code', 'BR,V,SV')

      this.$store.dispatch("moduleGetMedia/postMediaFiles", bodyFormData);

      // to display data inside slider
      this.searchCategories("Study Guides", this.getMedia);
    },
    actionPostMediaNR() {
      const bodyFormData = new FormData();
      bodyFormData.set("role_key", "NR");
      bodyFormData.set("lang", this.actionLanguage);
      bodyFormData.set("type", "neo Corporate Website");
      // bodyFormData.set('code', 'BR,V,SV')

      this.$store.dispatch("moduleGetMedia/postMediaFilesNR", bodyFormData);

      // to display data inside slider
      this.searchCategories("Study Guides", this.getMedia);
    },
    tabContent(val) {
      this.whichTab = val;
    },
    searchCategories(nameKey, myArray) {
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].category === nameKey) {
          setTimeout(() => {
            this.sortedCategories = myArray[i].data_category[0].media_files;
            this.showAgile = true;
          }, 1000);
        }
      }
    },
    langChange(i) {
      // console.log(i.target.value)
      this.actionLanguage = i.target.value;
      this.actionPostMedia();
    },
    onChangedChild(i) {
      // console.log(i);
      this.whichTab = i;
    },
    logout() {
      this.$store.dispatch("actLogout");
      this.$router.push("/neoagentresources");
      this.showModals = false;
    },
  },
};
</script>

<style>
</style>