<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
  >
    <path d="M16 10v-5l8 7-8 7v-5h-8v-4h8zm-16-8v20h14v-2h-12v-16h12v-2h-14z" :fill="iconColor" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 32,
    },
    height: {
      type: String,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>